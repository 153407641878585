import axios from 'axios';

const post  = async (api, params = {},headers) => {
    let reqUrl=api;
    if(api.startsWith("http")){
        reqUrl=api;
    }
    const result = await axios( {
        url:reqUrl,
        method: 'post',
        params: {},
        headers:headers||{},
        data: params,
        getResponse: true,
        responseType: 'json',

    });

    return result.data;
}


const get = async (api, params = {},headers) => {
    let reqUrl=api;
    if(api.startsWith("http")){
        reqUrl=api;
    }
    const result = await axios({
        url:reqUrl,
        method: 'get',
        params: params,
        headers:headers||{},
        data: {},
        getResponse: true,
        responseType: 'json',

    });
    return result.data;
};


const M = {};
const App = {
    _get: {},
    _begin: function () {
    },
    begin(callback) {
        App._begin = callback;
    },
    get (methodName, callback) {
        //在M.IO上注册一个方法
        M.IO.reg(methodName.replace("/", ""));
        App._get[methodName] = callback;
    },
    async doget(methodName,params,callback) {
        const req = {};
        const res = {};
        req.url = methodName;
        req.params = params;
        await App._begin(req, res);
        res.send = function (d) {
            callback(d);
        }.bind(this);
        App._get[methodName](req, res);
    }
};
//服务方法注册
M.IO = {};
M.IO.reg = function (methedName) {
    M.IO[methedName] = (param) => {
        return new Promise(
            function (reslove) {
                App.doget("/" + methedName,param,(d)=>{
                    reslove(d);
                })
            }
        )
    }
};
M.get = function (url, param) {
    let u;
    App.doget(url,param,(d)=>{
        u = d;
    });
    return u;
};

M.init = function () {
    //格式化日期
    Date.prototype.format = function (fmt) {
        var o = {
            "M+": this.getMonth() + 1,                 //月份
            "d+": this.getDate(),                    //日
            "h+": this.getHours(),                   //小时
            "m+": this.getMinutes(),                 //分
            "s+": this.getSeconds(),                 //秒
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度
            "S": this.getMilliseconds()             //毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (var k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return fmt;
    }
};

M.getParameter = function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.href.substr(window.location.href.indexOf('?')).substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
};

const MIO = M.IO;
const app=App;
M.app=App;
//将ajax请求挂到全局对象M上
M.init();

window.M=M;
window.MIO=MIO;
window.M.request={}
window.M.request.get=get;
window.M.request.post=post;
window.M.request.reqByReq = async (url,req) => {
    if(req.method==="get"){
        let result=  await get(url,req.params,req.headers)
        return result;
    }
    if(req.method==="post"){
        let result= await post(url,req.params,req.headers)
        return result;
    }
};

export {M,app,MIO}