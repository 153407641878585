const ApiReducer = (state = {
                        //最后一次点击的节点
                        currentTreeNode:{},
                        //最后一次点击的api
                        currentApi:{},
                    }
                    , action) => {
    switch (action.type) {
        case 'SET_API_STATE':
            return Object.assign({}, state, action.state)
        default:
            return state
    }
}

export  default ApiReducer;
