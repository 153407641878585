import React, { useEffect, useState } from 'react';
import apiSolution from '../../api/apiSolution';
import ParagraphStyles from '../Common/ParagraphStyles'
import FadeTransitions from '../Common/FadeTransitions';


const ActionDeskPlus = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            const result = await apiSolution.fetchActionDeskPlus();
            if (result.code === 200) {
                setData(result.data);
            }
        }
        fetch();
    },[]);

    return(
        <FadeTransitions>
            <div>
                <div>{ParagraphStyles.RenderTitle(data)}</div>
                <div>{ParagraphStyles.ContentStyles(ParagraphStyles.CommonContentRender(data))}</div>
            </div>
        </FadeTransitions>
    )
}

export default ActionDeskPlus;