import React, { Component } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy as twilight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { connect } from 'react-redux';

class ApiDoc extends Component {
    constructor(props) {
        super(props);
    }


    renderApi(){
        const currentApi=this.props.currentApi;
        return (<div style={{marginLeft: "10px",marginTop:"20px"}}>
            <div>
                <header>
                    api名称
                </header>
                <pre>
                    {currentApi.funcName}
                </pre>
            </div>
            <div>
                <header>
                    用途
                </header>
                <div>
                    <pre>
                         {currentApi.purpose}
                    </pre>
                </div>
            </div>
            <div>
                <header>
                    函数声明
                </header>
                <div>
                    <pre>
                        {currentApi.funDeclaration}
                    </pre>
                </div>
            </div>
            <div>
                <header>
                    参数
                </header>
                <div>
                     <pre>
                          {currentApi.paramsDesc}
                     </pre>
                </div>
            </div>
            <div>
                <header>
                    返回值
                </header>
                <div>
                  <pre>
                    {currentApi.returnDesc}
                  </pre>
                </div>
            </div>
            <div>
                <header>
                    调用说明
                </header>
                <div>
                    <pre>
                         {currentApi.callDesc}
                    </pre>
                </div>
            </div>
            <div>
                <header>
                    编程代码示例
                </header>
                <div>
                    <div style={{width:"60vw"}}>
                        <SyntaxHighlighter
                            lineNumberStyle={{color: 'red', fontSize: 10}}
                            startingLineNumber = {0}
                            showLineNumbers={true}
                            language="cpp"
                            wrapLines={true}
                            style={twilight}
                        >
                            {currentApi.codeSample}
                        </SyntaxHighlighter>
                    </div>
                </div>
            </div>
        </div>);


    }


    render() {

        let currentTreeNode= this.props.currentTreeNode;

       // alert(currentTreeNode.category)
        return (
            <div>
                {
                    currentTreeNode.category=="api" &&
                         this.renderApi()
                   }
            </div>

        )
    }
}

export default connect(u=>u.ApiReducer)(ApiDoc) ;