import React, { Component } from 'react';

class Cloud extends Component {

    componentWillMount() {
        
    }

    render() {
        return (
            <div>提供了日常所需的查询，下载，管理，备份等服务</div>
        )
    }
}

export default Cloud;