import React, { Component, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import '../../public/css/hoverStyle.css';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, IconButton, useMediaQuery, Button, Divider, Snackbar, AppBar, Tabs,Tab } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import CONFIG from '../../config';
import apiService from '../../api/apiService';
import { Alert } from '@material-ui/lab';
// import { TabContext, TabList, TabPanel } from '@material-ui/lab'
var that

    //朗杰服务 产品、维修、合同搜索框
   function SearchBarComponent (children){
        const useStyles = makeStyles(theme => ({
            root: {
                padding: "2px 4px",
                display: 'flex',
                alignItems: 'center',
                border: "#3F51B5 1px solid"
            },
            container: {
                maxHeight: 540,
            },
            input: {
                marginLeft: theme.spacing(1),
                flex: 1
            },
            IconButton: {
                padding: 10
            },
            divider: {
                height: 28, 
                margin: 4
            }
        }))
        const classes = useStyles()
        const isPc = useMediaQuery(CONFIG.minDeviceWidth)
        const [inputVal, setInputVal] = useState()
        const searchFetch = () => {
            children.searchInput(inputVal)
            //产品、 维修、 合同查询搜索接口
        }

        const resetSearchVal = () => {
            children.resetSearchInput()
            setInputVal("")
            document.getElementById('input').value = ''
        }

        return(
            <div style={{margin: isPc ? "30px 20px": "10px"}}>
                <Paper component="form" className={classes.root}>
                    <InputBase
                    id="input"
                    className={classes.input}
                    placeholder={'请输入例程名'}
                    onChange={ e => setInputVal(e.target.value)}
                    />
                    <Button className={classes.IconButton} onClick={searchFetch}><SearchIcon/></Button>
                    <Divider orientation="vertical" className={classes.divider} />
                    <Button variant="text" color="primary" onClick={resetSearchVal}>重置</Button>
                </Paper>
            </div>
        )
    }
    //左侧图标
    function IconSuffixName(openSourceItem) {
       if(openSourceItem==null){
           return <div></div>
       }
       return <img src={openSourceItem.cover_photo_oss_url} style={{width: 60, height: 60}}></img>
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        }
    }));

    function TabPanel(props) {
        const { children, value, index, parentProps, renderListStar, itemSelected, isAdmin, ...other } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
          >
            {value === index && (
              <div>{children.map((items, index) => (
                <div className='hoverItem' key={items.id + '_' + index} style={{display: 'flex', cursor: 'pointer', background : index === 0 ? '#f8f8f8' : '#eee'}}>
                    { isAdmin ? null : value === 0 ? renderListStar(items) : null }
                    <div style={{ display: 'flex', padding: 8, marginBottom: 4, width: '100%' }} onClick={() => itemSelected(items, index)}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>{items.fileSuffixIcon}<p>{items.type}</p></div> 
                        { parentProps.renderList(items) }
                    </div>
                </div>
            ))}</div>
            )}
          </div>
        );
      }
    function LabTabs(props) {
        const tabsLabelName = ['我的下载','公共下载']
        const { renderListStar, parentProps, children, itemSelected, isAdmin } = props
        const classes = useStyles();
        const [value, setValue] = useState(0);
        const handleChange = async (event, newValue) => {
          setValue(newValue);
          props.changeTabsFetchList(newValue)
        };
      
        return (
            <div className={classes.root}>
                {
                isAdmin === true ? 
                <TabPanel
                    value={value} 
                    index={0}
                    renderListStar={renderListStar} 
                    parentProps={parentProps} 
                    itemSelected={itemSelected}
                    isAdmin={isAdmin}
                    >    
                        {children}
                    </TabPanel>
                    : <div>
                        <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        >
                    {tabsLabelName.map((item, index) => <Tab key={index} label={item}/>)}
                </Tabs>
                        {
                            tabsLabelName.map((item, index) => 
                                <TabPanel
                                key={index} 
                                value={value} 
                                index={index} 
                                renderListStar={renderListStar} 
                                parentProps={parentProps} 
                                itemSelected={itemSelected}
                                isAdmin={isAdmin}
                                >
                                    {children}
                                </TabPanel>
                            )
                        }
                    </div>
                }
            
          </div>
        );
      }

class OpenSourceItemList extends Component {

    constructor() {
        super();
        this.fetch = this.fetch.bind(this);
    }

    state = {
        list: [],
        scroll: {
            page: 1,
            pageSize: 30,
            hasMore: true,
            loading: false,
        },
        keywords: '',
        openSnackbar: false,
        searchList: [],
    };

    async componentWillMount() {
        that = this
       // console.log(fetchCloudDiskList)
        this.fetch()

    }

    /**
     * 节流函数
     * @param {*} fun 要执行的函数
     * @param {*} delay 延迟
     * @param {*} time 在time时间内必须执行一次
     */
    throttle(fun, delay, time) {
        var timeout,
            startTime = new Date();
        return function () {
            var context = this,
                args = arguments,
                curTime = new Date();
            clearTimeout(timeout);
            // 如果达到了规定的触发时间间隔，触发 handler
            if (curTime - startTime >= time) {
                fun.apply(context, args);
                startTime = curTime;
                // 没达到触发间隔，重新设定定时器
            } else {
                timeout = setTimeout(function () {
                    fun.apply(context, args);
                }, delay);
            }
        };
    };

    async fetch() {
        const { scroll, list} = this.state;
        let result
        scroll.loading = true;
        this.setState({
            scroll,
        });
        let infoList = []
        result = await this.props.fetchList({
            page: scroll.page,
            pageSize: scroll.pageSize,
        });
        scroll.loading = false;
        scroll.page++;
        try {
            infoList = result.data.rows
            infoList.map(item => {
                item.fileSuffixIcon = IconSuffixName(item)
            })
        } catch (error) {
            //infoList = result.data
        }
        this.setState({
            list: [...list, ...infoList],
            scroll,
        }, () => {
            if (scroll.page === 2 && infoList.length !== 0 && this.props.isPc) {
                this.itemSelected(infoList[0], 0);
            }
        });
        
    }

    itemSelected(item, index) {
        that.props.backSelectedItem(item);
        const len = document.getElementsByClassName('hoverItem').length
        for (let i = 0; i < len; i++) {
            document.getElementsByClassName('hoverItem')[i].style.background = '#eee';
        }
        try {
            document.getElementsByClassName('hoverItem')[index].style.background = '#f8f8f8';
            this.props.backSelectedItem(item);
        } catch (e) {
            
        }
    }

    searchInput(param){
        that.searchFetch(param)   
    }
    async resetSearchInput(){
        const type = that.props.serviceType
        const { scroll } = that.state
        scroll.page = 1
        that.setState({
            list: [],
            scroll,
            keywords: ''
        }, () => {
            that.fetch()
        })
    }
    

    async searchFetch(param) {
        const type = this.props.serviceType
        const { scroll } = this.state;
        scroll.loading = true;
        scroll.page = 1
        this.setState({
            scroll,
            keywords: param
        });
        let searchResult
        searchResult = await this.props.fetchList({
            page: scroll.page,
            pageSize: scroll.pageSize,
            keywords: param
        });
        scroll.loading = false;
        scroll.page++;
        searchResult.data.rows.map(item => {
            item.fileSuffixIcon = IconSuffixName(item)
        })
        if (searchResult.data.rows.length === 0) scroll.hasMore = false;
        this.setState({
            list: [...searchResult.data.rows],
            scroll,
        }, () => {
            if (scroll.page === 2 && searchResult.data.rows.length !== 0 && this.props.isPc) {
                this.itemSelected(searchResult.data.rows[0], 0);
            }
        });
    }

    renderListStar = items => {
        const openSnackbar = this.state.openSnackbar
        const handleChangeStar = async () => {
            const list = this.state.list
            const result = await apiService.changeCloudDiskStar({
                id: items._id,
                star: !items.isStar
            })
            if (result.code === 200) {
                list.map(item => {
                    if (item._id === items._id) {
                        item.isStar = !items.isStar
                    }
                })
                this.setState({
                    list,
                    openSnackbar: true
                })
                setTimeout(() => {
                    this.setState({
                        openSnackbar: false
                    })
                }, 2000)
            }
        }
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div onClick={() => handleChangeStar()} style={{margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {items.isStar === true ?
                        <svg t="1608520080739" className="icon" style={{width: 20, height: 20}} viewBox="0 0 1102 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12942" width="200" height="200"><path d="M1038.099692 455.128615l-193.772307 163.052308a50.254769 50.254769 0 0 0-14.966154 44.977231l45.764923 261.907692c3.623385 20.716308 0 35.603692-17.329231 48.049231a57.107692 57.107692 0 0 1-59.392 4.174769L553.747692 847.793231a52.617846 52.617846 0 0 0-48.128 0L270.966154 977.289846a57.265231 57.265231 0 0 1-59.392-4.174769c-17.329231-12.445538-31.192615-27.254154-27.569231-48.049231L240.246154 657.723077a50.254769 50.254769 0 0 0-14.887385-44.977231L21.267692 455.128615a54.587077 54.587077 0 0 1-14.336-56.713846 56.162462 56.162462 0 0 1 45.528616-37.651692L317.833846 315.076923c16.935385-2.441846 31.507692-12.839385 38.990769-27.884308L479.074462 33.949538A56.398769 56.398769 0 0 1 529.644308 3.150769c21.504 0 40.96 11.972923 50.569846 30.798769l117.366154 253.243077c7.561846 14.966154 22.134154 25.442462 38.990769 27.805539l270.336 45.686154c21.267692 3.150769 38.833231 17.723077 45.449846 37.730461a54.744615 54.744615 0 0 1-14.257231 56.713846z" fill="#FDD700" p-id="12943"></path></svg>
                        : <svg t="1608520160468" className="icon" style={{width: 20, height: 20}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13076" width="200" height="200"><path d="M569.498552 94.591355l110.452601 223.834928 273.26778 40.687678c49.603745 7.397481 65.189721 42.312688 28.735461 80.583317l-199.268416 195.119933 48.854685 284.620339c6.563486 41.200354-33.247218 65.146743-68.225871 41.200354l-249.727645-130.273019L259.392772 963.953416c-34.038234 17.381879-69.038376 6.200212-62.688761-41.458227l47.764863-287.656489L39.10667 435.399391c-39.661302-36.582173-19.049868-69.979818 26.982538-76.094072l281.199427-40.85857c0 0 57.706283-117.0386 110.324688-223.64357C495.927953 23.221888 537.940812 24.269753 569.498552 94.591355z" p-id="13077"></path></svg>
                    }
                </div>
                <Snackbar anchorOrigin={{vertical: 'top',horizontal: 'center',}} open={openSnackbar} autoHideDuration={2}>
                    <Alert severity="success">操作成功</Alert>
                </Snackbar>
            </div>
        )
    }

    /**
     * 切换Tab
     */
    changeTabsFetchList = async e => {
        let { list, scroll } = this.state
        list = []
        scroll.page = 1
        if (e === 0) {
            this.setState({fetchListType: 'fetchCloudDiskList', list, scroll}, () => this.fetch())
        } else {
            this.setState({fetchListType: 'fetchCloudDiskPublicList', list, scroll}, () => this.fetch())
        }
    }

    render() {
        const { list, scroll} = this.state;
        return (
            <InfiniteScroll
                initialLoad={false}
                pageStart={scroll.page}
                isReverse={false}
                loadMore={this.throttle(this.fetch, 500, 1000)}
                hasMore={scroll.hasMore}
                useWindow={false}
                threshold={1}
            >
                <div style={{position: 'sticky'}}><SearchBarComponent children={this.props} searchInput={this.searchInput} resetSearchInput={this.resetSearchInput}/></div>
                <div style={{background: "#eee", color: "#333"}}>
                     <div><LabTabs changeTabsFetchList={this.changeTabsFetchList} renderListStar={this.renderListStar} parentProps={this.props} itemSelected={this.itemSelected} isAdmin={true}>{list}</LabTabs></div>
                </div>
            </InfiniteScroll>
        )
    }
    
}

export default OpenSourceItemList;