import AboutLangjie from './components/Home/AboutLangjie'
import WxPublicPlat from './components/Home/WxPublicPlat'
import Activity from './components/Home/Activity'
import EventRecord from './components/Home/EventRecord'
import ContactUs from './components/Home/ContactUs'
import ToolBox from './components/Solution/ToolBox'
import ActionLaboratory from './components/Solution/ActionLaboratory'
import CtrlProducts from './components/Solution/CtrlProducts'
import ActionTeam from './components/Solution/ActionTeam'
import ActionCloud from './components/Solution/ActionCloud'
import Dyna from './components/Solution/Dyna'
import Vir from './components/Solution/Vir'
import MaxTest from './components/Solution/MaxTest'
import DynaTest from './components/Solution/DynaTest'
import ActionPlat from './components/Solution/ActionPlat'
import Application from './components/Solution/Application'
import Cloud from './components/Service/Cloud'
import VirCard from './components/Service/VirCard'
import Repair from './components/Service/Repair'
import Contract from './components/Service/Contract'
import GoodsList from './components/Shop/GoodsList'
import CloudDisk from './components/Service/CloudDisk'
import OpenSourceRoutine from './components/Service/OpenSourceRoutine'
import AboutMemberVipClub from './components/MemberVipclub/AboutMemberVipClub'
import MemberVipConstituent from "./components/MemberVipclub/MemberVipConstituent";
import MemberVipClubActivity from "./components/MemberVipclub/MemberVipClubActivity";
import MemberVipJoinUs from "./components/MemberVipclub/MemberVipJoinUs";
import MemberVipServer from "./components/MemberVipclub/MemberVipServer";
import ActionDeskPlus from "./components/Solution/ActionDeskPlus";
import ActionOnline from "./components/Solution/ActionOnline";
import ActionDesk from "./components/Solution/ActionDesk";
import ActionPro from "./components/Solution/ActionPro";
import Play from "./components/Solution/Play";
import Action from "./components/Solution/Action";
import Mgt from "./components/Solution/Mgt";
import Vtc from "./components/Solution/Vtc";


const CONFIG = {
    baseUrlCrmHelp:(url) => 'https://crmhelp.langjie.com'+url,
    url: pathname => 'https://www.langjie.com' + pathname,
    webSiteUrl: 'https://www.langjie.com',
    shopUrl: 'https://shop.langjie.com',
    wxLoginAppid: 'wx1dbbbe221c943cd9',
    minDeviceWidthNum: 800,
    minDeviceWidth: '(min-width:800px)',
    indexPageMaxWidth: 1300,
    activeMenuColor: '#ffde03',
    defaultIndexTitle: '杭州朗杰测控技术开发有限公司',
    menu: [
        {
            id: -1,
            text: '关于朗杰®',
            type: 'home',
            pathname: '/homePage',
            component: AboutLangjie,
            auth: false,
            subArr: [
                {
                    id: 1,
                    supId: -1,
                    text: '关于朗杰®',
                    type: 'home',
                    pathname: '/home/aboutLangjie',     
                    component: AboutLangjie,               
                    auth: false,
                },
                {
                    id: 2,
                    supId: -1,
                    text: '推荐阅读',
                    type: 'home',
                    pathname: '/home/recommendReading', 
                    component: WxPublicPlat,                   
                    auth: false,
                },
                {
                    id: 3,
                    supId: -1,
                    text: '近期活动',
                    type: 'home',
                    pathname: '/home/activity',
                    component: Activity,                        
                    auth: false,
                },
                {
                    id: 4,
                    supId: -1,
                    text: '大事记',
                    type: 'home',
                    pathname: '/home/eventRecord',   
                    component: EventRecord,                
                    auth: false,
                },
                {
                    id: 5,
                    supId: -1,
                    text: '联系我们',
                    type: 'home',
                    pathname: '/home/contactUs',  
                    component: ContactUs,                  
                    auth: false,
                },
            ],
        },
        {
            id: -2,
            text: '安可迅®',
            type: 'solution',
            pathname: '/solutionPage',
            component: Action,
            auth: false,
            subArr: [
                {
                    id: 39,
                    supId: -2,
                    text: '安可迅®',
                    pathname: '/solution/action',
                    component: Action,
                    type: 'solution',
                    auth: false,
                },
                {
                    id: 40,
                    supId: -2,
                    text: '安可迅®云',
                    pathname: '/solution/actionCloud',
                    component: ActionCloud,
                    auth: false,
                    type: 'solution',
                    subArr: [
                        {
                            id: 41,
                            supId: -2,
                            text: '安可迅®桌面+',
                            pathname: '/solution/actionDeskPlus',
                            component: ActionDeskPlus,
                            auth: false,
                            type: 'solution',
                        },
                        {
                            id: 42,
                            supId: -2,
                            text: '安可迅®在线',
                            pathname: '/solution/actionOnline',
                            component: ActionOnline,
                            auth: false,
                            type: 'solution',
                        },
                    ]
                },
                {
                    id: 43,
                    supId: -2,
                    text: '安可迅®桌面',
                    pathname: '/solution/actionDesk',
                    component: ActionDesk,
                    type: 'solution',
                    auth: false,
                    subArr: [
                        {
                            id: 44,
                            supId: -2,
                            text: 'MaxTest®',
                            pathname: '/solution/maxTest',
                            component: MaxTest,
                            auth: false,
                            type: 'solution',
                        },
                        {
                            id: 45,
                            supId: -2,
                            text: 'DynaTest®',
                            pathname: '/solution/dynaTest',
                            component: DynaTest,
                            auth: false,
                            type: 'solution',
                        },
                        {
                            id: 46,
                            supId: -2,
                            text: 'ActionPro',
                            pathname: '/solution/actionPro',
                            component: ActionPro,
                            type: 'solution',
                            auth: false,
                        },
                        {
                            id: 47,
                            supId: -2,
                            text: 'ActionKit',
                            pathname: '/solution/actionKit',
                            component: ToolBox,
                            type: 'solution',
                            auth: false,
                        },
                        // {
                        //     id: 48,
                        //     supId: -2,
                        //     text: 'Play',
                        //     pathname: '/solution/play',
                        //     component: Play,
                        //     type: 'solution',
                        //     auth: false,
                        // },
                    ]
                },
                {
                    id: 49,
                    supId: -2,
                    text: '安可迅®平台',
                    pathname: '/solution/actionPlat',
                    component: ActionPlat,
                    auth: false,
                    type: 'solution',
                    subArr: [
                        {
                            id: 50,
                            supId: -2,
                            text: 'VTC软件架构',
                            pathname: '/solution/vtc',
                            component: Vtc,
                            auth: false,
                            type: 'solution',
                        },
                        {
                            id: 51,
                            supId: -2,
                            text: 'MGT软件架构',
                            pathname: '/solution/mgt',
                            component: Mgt,
                            auth: false,
                            type: 'solution',
                        },
                    ]
                },
                {
                    id: 52,
                    supId: -2,
                    text: '安可迅®控制器',
                    pathname: '/solution/ctrlProducts',
                    component: CtrlProducts,
                    auth: false,
                    type: 'solution',
                    subArr: [
                        {
                            id: 53,
                            supId: -2,
                            text: '威程®',
                            pathname: '/solution/vir',
                            component: Vir,
                            auth: false,
                            type: 'solution',
                        },
                        {
                            id: 54,
                            supId: -2,
                            text: '代龙®',
                            pathname: '/solution/dyna',
                            component: Dyna,
                            auth: false,
                            type: 'solution',
                        },
                    ]
                },
            ],
        },
        {
            id: -6,
            text: '如云随行®服务',
            type: 'suixing',
            pathname: '/suixing',
            component: Cloud,
            auth: false,
            subArr: []
        }
    ],
    singlePage: [
        {
            pathname: '/readingContent',
            menuId: 2,
        },
        {
            pathname: '/activityContent',
            menuId: 3,
        },
        {
            pathname: '/vipClubActivityContent',
            menuId: 32,
        },
        {
            pathname: '/docDetail',
            menuId: 21,
            rootId: -2
        },
        {
            pathname: '/virProInfo',
            menuId: 24,
            rootId: -2 
        },
        {
            pathname: '/dynaProInfo',
            menuId: 21,
            rootId: -2
        },
    ],
};

export default CONFIG;