import CONFIG from  "../config.js";
import {M,app,MIO} from  "../lib/petite_ming_mock.js";
import Common from "../components/Common/Common.js"


app.get("/mingIsLogin",(req,res)=>{
    let token= Common.getAuthToken();
    if(token==null){
        res.send(false)
    }else {
        res.send(true)
    }
})


function convertURLToFileName(url) {
    var index = url.lastIndexOf("/");
    var fileName = url.substring(index + 1);
    return fileName;
}


MIO.df=function (){
    const ossUrl=arguments[0];
    debugger
    let token= Common.getAuthToken();
    if(!token){
        const pathname =window.location.hash.replace("#","");
        window.location.href="/#/login?path="+pathname
        return;
    }else {
        window.open(arguments[0])
    }

}
