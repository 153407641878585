import devConfig from './config-dev'
import prodConfig from './config-prod'

const env=window.globalConfig.env;

const CONFIG= prodConfig;



export default CONFIG;


